import { Controller } from '@hotwired/stimulus';
import { useIntersection } from 'stimulus-use';

export default class extends Controller {
  static classes = ['toggle'];

  connect() {
    useIntersection(this, {
      threshold: 1
    });
    this.checkInitialState();
  }

  checkInitialState() {
    if (this.isVisible()) {
      this.appear({ target: this.element });
    } else {
      this.disappear({ target: this.element });
    }
  }

  appear(entry) {
    entry.target.classList.remove(...this.toggleClasses);
  }

  disappear(entry) {
    entry.target.classList.add(...this.toggleClasses);
  }
}
